import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {environment} from '../../../environments/environment';
import {AUTH_ME_STORAGE, SELECTED_PROJECT} from '../constants/const';

@Injectable({
  providedIn: 'root'
})
export class CustomService {
  messageSource1:BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  currentMessage1 = this.messageSource1.asObservable();
  messageSource:BehaviorSubject<any[]>= new BehaviorSubject([]);
  currentMessage = this.messageSource.asObservable();
  messageSource2:BehaviorSubject<string>= new BehaviorSubject('');
  currentMessage2 = this.messageSource2.asObservable();

  itineratydaybyday:BehaviorSubject<any[]>= new BehaviorSubject([]);
  daybyday = this.itineratydaybyday.asObservable();

  itineraty_new:BehaviorSubject<string>= new BehaviorSubject('');
  daybyday_new = this.itineraty_new.asObservable();

  itineraty_new_trip:BehaviorSubject<string>= new BehaviorSubject('');
  daybyday_new_trip = this.itineraty_new_trip.asObservable();

  reset_template:BehaviorSubject<boolean>= new BehaviorSubject(false);

  reset_t = this.reset_template.asObservable();

  constructor() {
  }


  private getAuthData(): any {
    return JSON.parse(localStorage.getItem(AUTH_ME_STORAGE));
  }

  private isSuperAdmin(): boolean {
    return this.getAuthData()?.role === 'super_admin';
  }

  changeMessage1(message) {
    this.messageSource1.next(message)
  }
  changeMessage2(message) {
    this.messageSource2.next(message)
  }
  changeMessage(message) {
    this.messageSource.next(message)
  }
  public get getdaybyday(){
   return this.itineratydaybyday.value
  }
  public setdaybyday(days){
    this.itineratydaybyday.next(days)
  }
  public setdaybyday_new(days){
    console.log(days);

    this.itineraty_new.next(days)
  }
  public setdaybyday_new_trip(days){
    this.itineraty_new_trip.next(days)
  }
  public reset_day_template(days){
    this.reset_template.next(days)
  }

  public getB2CUrl(){
    return this.isBamba() ? environment.B2C_BAMBA_URL : environment.B2C_URL;
  }

  changeSelectedProject(project: string): void {
    if (this.isSuperAdmin()) {
      localStorage.setItem(SELECTED_PROJECT, project);
    }
    window.location.href = '/';
  }

  getSelectedProject(): string {
    return localStorage.getItem(SELECTED_PROJECT) || null;
  }

  isBamba() {
    const bambaOrigins = [
      'https://devdashboard.bambatravel.com',
      'https://stagingdashboard.bambatravel.com',
      'https://dashboard.bambatravel.com'
    ];

    const isBambaOrigin = bambaOrigins.includes(window.location.origin) ||
      window.location.href.includes('localhost');

    if (this.isSuperAdmin()) {
      const selectedProject = this.getSelectedProject();

      if (isBambaOrigin && !selectedProject) {
        this.changeSelectedProject('bamba');
        return true;
      }

      if (!selectedProject) {
        this.changeSelectedProject('baboo');
        return false;
      }

      return selectedProject === 'bamba';
    }

    return isBambaOrigin;
  }
}
